import { useContext } from 'react';

import AuthState from './AuthState';

export const useCurrentUser = () => {
  const { state, unauthenticate, getUserDetails } = useContext(AuthState);

  const hasPermission = (permissionName: string) => {
    return (
      state.user &&
      state.user.permissions &&
      !!state.user.permissions.find((permission) => permission.name === permissionName)
    );
  };

  return {
    user: state.user,
    token: state.token,
    hasPermission,
    refetchUserDetails: getUserDetails,
    unauthenticate,
  };
};
