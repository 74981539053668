import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { parse } from 'graphql';

import { logRQGraphqlError } from 'services/log';

import useApi from './useApi';

/**
 * @typedef {Object} CustomConfig
 * @property {Object} [variables] - Query variables
 * @property {string | Object} [queryKey]
 * @property {boolean} [logError]
 * @property {string} [origin]
 */

/**
 * @template TData
 * @template TError
 * @template TSelect
 * @param {string} query - GraphQL query
 * @param {import('@tanstack/react-query').UseQueryOptions<TData, TError, TSelect> & CustomConfig} config - Query config
 */
const useGqlQuery = (query, config) => {
  const api = useApi();

  if (import.meta.env.DEV) {
    if (config.queryKey == null) {
      throw new Error('Query key is required');
    }

    if (!Array.isArray(config.queryKey)) {
      throw new Error('Query key must be an array');
    }
  }

  const operationName = useMemo(() => {
    const parsedQuery = parse(query);

    const queryOperation = parsedQuery?.definitions?.filter?.((x) => x.kind === 'OperationDefinition');

    return queryOperation?.[0]?.name?.value;
  }, [query]);

  const result = useQuery(
    config.queryKey.concat(query),
    async ({ signal }) => {
      try {
        const res = await api.post(
          `/graphql/${operationName}`,
          {
            query,
            variables: config.variables || {},
            operationName,
          },
          {
            signal,
          },
        );

        // case when the api returns 200 but the query response has errors
        if (!!res.errors?.length) {
          throw new Error(res.errors?.[0]?.message);
        }

        return res.data;
      } catch (error) {
        let thrownError;
        if (error.isAxiosError) {
          thrownError = error.response.data.errors;
        } else {
          thrownError = error;
        }

        if (config.logError) {
          logRQGraphqlError({
            error,
            origin: config.origin || `${operationName} Query`,
            variables: config.variables,
          });
        }

        throw thrownError;
      }
    },
    config,
  );

  if (import.meta.env.DEV) {
    return new Proxy(result, {
      get(target, prop) {
        if (prop === 'loading') {
          throw new Error('`loading` flag is deprecated, please use `isLoading`');
        }

        return target[prop];
      },
    });
  }

  return result;
};

export default useGqlQuery;
