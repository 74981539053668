// url helpers
import queryString from 'query-string';
import isValid from 'date-fns/isValid';

import { FLOWLOGS_PROFILE_TYPES, IDENTITY_PROFILE_TYPES, type PROFILE_TYPE } from 'consts/sensors';
import { SCENE } from 'consts/scenes';
import { isValidIp } from 'utils/string';
import type { LooseAutocomplete, ValueOf } from 'types/helpers';

import { prepareTimerangeQuery } from './pql';

type QueryObject<T = string> = Record<string, T | number | null | undefined | Array<T | null>>;
type FilteredQueryObject<T = string> = Record<string, T | number | Array<T>>;

export function parseQueryString(str: string | null = null) {
  const qs = str || window.location.search;
  return queryString.parse(qs) as FilteredQueryObject;
}

//
export function updateQueryString(key: string, value: string | undefined, pathname: string | null = null) {
  const parsed = queryString.parseUrl(window.location.href);
  const newQuery = Object.assign(parsed.query, { [key]: value });
  const newQueryString = queryString.stringify(newQuery);
  const newPathname = pathname || window.location.pathname;
  return `${newPathname}?${newQueryString}`;
}

/**
 * updates multiple key: value params
 *
 * ie: queryObj = { q: query, r: recordname }
 */
export function updateQueryStringMultiple(queryObj: QueryObject, pathname: string | null = null) {
  const parsed = queryString.parseUrl(window.location.href);
  const newQuery = Object.assign(parsed.query, queryObj);

  const newQueryString = queryString.stringify(newQuery);
  const newPathname = pathname || window.location.pathname;
  return `${newPathname}?${newQueryString}`;
}

/**
 *  does NOT carry over existing query string params
 */
export function setQueryStringMultiple(queryObj: QueryObject, pathname: string | null = null) {
  const newQueryString = queryString.stringify(queryObj);
  const newPathname = pathname || window.location.pathname;
  return `${newPathname}?${newQueryString}`;
}

export function removeKeyFromQueryString(key: string, str: string | null = null) {
  const parsed = parseQueryString(str) as QueryObject;
  parsed[key] = undefined;
  return queryString.stringify(parsed);
}

export function removeKeyFromQueryStringMultiple(keys: string[] = [], str: string | null = null) {
  const parsed = parseQueryString(str) as QueryObject;
  keys.forEach((key) => (parsed[key] = undefined));
  return queryString.stringify(parsed);
}

export const isValidTimestamp = (timestamp: Date | string | number) => {
  return !!timestamp && isValid(new Date(timestamp));
};

export function fixInvalidTimerange(str: string, defaultQuery = 'Last 2 hours') {
  const parsed = parseQueryString(str) as QueryObject;

  const hasInvalidTimestamps =
    (parsed.from && !isValidTimestamp(+parsed.from)) || (parsed.to && !isValidTimestamp(+parsed.to));

  if (hasInvalidTimestamps || (!parsed.from && !parsed.to && !parsed.q)) {
    parsed.from = undefined;
    parsed.to = undefined;
    parsed.q = defaultQuery;
  }

  return queryString.stringify(parsed);
}

export function getDebugFlag(str: string) {
  return queryString.parse(str).debug;
}

export function getPivotToIndicators(query: string) {
  return updateQueryStringMultiple({ q: query, r: undefined, alert: undefined }, SCENE.indicators.path);
}

export function removeSidebarIdsFromQueryString(str: string) {
  const parsed = queryString.parse(str) as QueryObject;
  parsed.alert = undefined;
  parsed.r = undefined;
  return queryString.stringify(parsed);
}

export function getTokenFromUrl(str: string): string | undefined {
  return parseQueryString(str).token as string | undefined;
}

export function getPivotToInvestigator(query: string) {
  return updateQueryStringMultiple({ q: query, r: undefined, alert: undefined }, SCENE.investigator.path);
}

/**
 * TODO check if we can reuse this across multiple places
 */
const ENTITY_FIELD_SIDEBAR = {
  'data.okta_logs.eventType.keyword': 'eventType',
  'geo.country_iso_code.keyword': 'geo',
  'data.okta_ext.appinstance.displayName.keyword': 'appinstance',
  'data.okta_logs.actor.alternateId.keyword': 'actor',
};

type SidebarFields = keyof typeof ENTITY_FIELD_SIDEBAR;

type EntityField = LooseAutocomplete<SidebarFields>;

export const createPivotUriByEntity = ({
  profileType,
  entity,
  entityField,
  sensorId,
  t0,
  t1,
}: {
  profileType: ValueOf<typeof PROFILE_TYPE>;
  entity: string | number;
  entityField?: EntityField;
  sensorId: number;
  t0: number;
  t1: number;
}) => {
  const isOktaSidebarDefined = (entityField: SidebarFields): entityField is keyof typeof ENTITY_FIELD_SIDEBAR => {
    return Boolean(ENTITY_FIELD_SIDEBAR[entityField]);
  };

  if (
    IDENTITY_PROFILE_TYPES.includes(profileType) &&
    entityField &&
    isOktaSidebarDefined(entityField as SidebarFields)
  ) {
    const sidebar = ENTITY_FIELD_SIDEBAR[entityField as SidebarFields];

    return setQueryStringMultiple({ from: t0, to: t1, [sidebar]: entity }, SCENE.identityManagement.path);
  }

  if (FLOWLOGS_PROFILE_TYPES.includes(profileType)) {
    return setQueryStringMultiple({ from: t0, to: t1, e: entity }, SCENE.flowlogs.path);
  }

  // if (ACTIVITYLOG_PROFILE_TYPES.includes(profileType)) {
  //   const timerange = t0 && t1 ? prepareTimerangeQuery(t0, t1) : '';
  //   const query = `${timerange} ip = ${mqlSafeEntity} sensorId = ${sensorId}`;
  //   return setQueryStringMultiple({ q: query }, dashboardPath);
  // }

  if (!entity || !entityField || !sensorId || !t0 || !t1) {
    throw new Error('missing required param');
  }

  const mqlSafeEntity = typeof entity === 'string' ? (isValidIp(entity) ? entity : `"${entity}"`) : entity;

  const timerange = prepareTimerangeQuery(t0, t1);

  const query = `${timerange} ${entityField} = ${mqlSafeEntity} sensorId = ${sensorId}`;

  return setQueryStringMultiple({ q: query }, SCENE.investigator.path);
};

export const createPivotUriByEventImpact = (impact: string, t0: number | undefined, t1: number | undefined) => {
  const timerange = t0 && t1 ? prepareTimerangeQuery(t0, t1) : '';
  const query = `${timerange} impact = "${impact}"`;
  return setQueryStringMultiple({ q: query, view: 'intel-events' }, '/alerting/detections');
};

export function compareUrlParams(a: string, b: string) {
  // compare two query strings
  const aParams = queryString.parse(a);
  const bParams = queryString.parse(b);

  if (a.length !== b.length) {
    return false;
  }

  const aKeys = Object.keys(aParams);
  const bKeys = Object.keys(bParams);

  if (aKeys.length !== bKeys.length) {
    return false;
  }

  return aKeys.every((key) => {
    // sort arrays before comparing
    if (Array.isArray(aParams[key]) && Array.isArray(bParams[key])) {
      const aParamsKey = aParams[key];
      const bParamsKey = bParams[key];
      return JSON.stringify(aParamsKey.sort()) === JSON.stringify(bParamsKey.sort());
    }
    return JSON.stringify(aParams[key]) === JSON.stringify(bParams[key]);
  });
}
