import { PAGES } from 'consts/pages';
import type { ValueOf } from 'types/helpers';

import {
  ZEEK_SENSOR_PROFILE_TYPES,
  ACTIVITYLOG_PROFILE_TYPES,
  FLOWLOGS_PROFILE_TYPES,
  IDENTITY_PROFILE_TYPES,
  PROFILE_TYPE,
  CROWDSTRIKE_PROFILE_TYPES,
  AZURE_AD_PROFILE_TYPES,
} from './sensors';

export const SCENE = {
  alerts: {
    path: '/alerting/overview',
    profileTypes: [
      ...ZEEK_SENSOR_PROFILE_TYPES,
      ...ACTIVITYLOG_PROFILE_TYPES,
      ...FLOWLOGS_PROFILE_TYPES,
      ...IDENTITY_PROFILE_TYPES,
      ...CROWDSTRIKE_PROFILE_TYPES,
      PROFILE_TYPE.awsApiGatewayLogs,
      PROFILE_TYPE.kafkaAzureAD,
    ],
  },
  aiOverview: {
    path: '/alerting/ai-overview',
    profileTypes: ZEEK_SENSOR_PROFILE_TYPES,
  },
  [PAGES.detections]: {
    path: '/alerting/detections',
    profileTypes: [
      ...ZEEK_SENSOR_PROFILE_TYPES,
      ...ACTIVITYLOG_PROFILE_TYPES,
      ...AZURE_AD_PROFILE_TYPES,
      ...CROWDSTRIKE_PROFILE_TYPES,
      ...FLOWLOGS_PROFILE_TYPES,
      ...IDENTITY_PROFILE_TYPES,
      PROFILE_TYPE.awsApiGatewayLogs,
      PROFILE_TYPE.kafkaAzureAD,
    ],
  },
  [PAGES.cloudapi]: {
    path: '/alerting/cloudapi',
    profileTypes: [...ACTIVITYLOG_PROFILE_TYPES, PROFILE_TYPE.awsApiGatewayLogs],
  },
  [PAGES.flowlogs]: {
    path: '/alerting/flowlogs',
    profileTypes: FLOWLOGS_PROFILE_TYPES,
  },
  [PAGES.identityManagement]: {
    path: '/alerting/identity-management',
    profileTypes: [...IDENTITY_PROFILE_TYPES, PROFILE_TYPE.kafkaAzureAD],
  },
  [PAGES.indicators]: {
    path: '/investigation/indicators',
    profileTypes: [
      ...ZEEK_SENSOR_PROFILE_TYPES,
      ...ACTIVITYLOG_PROFILE_TYPES,
      ...IDENTITY_PROFILE_TYPES,
      ...AZURE_AD_PROFILE_TYPES,
      ...CROWDSTRIKE_PROFILE_TYPES,
      PROFILE_TYPE.awsApiGatewayLogs,
      PROFILE_TYPE.kafkaAzureAD,
    ],
  },
  [PAGES.investigator]: {
    path: '/investigation/logs',
    profileTypes: [
      ...ZEEK_SENSOR_PROFILE_TYPES,
      ...ACTIVITYLOG_PROFILE_TYPES,
      ...IDENTITY_PROFILE_TYPES,
      ...CROWDSTRIKE_PROFILE_TYPES,
      PROFILE_TYPE.awsApiGatewayLogs,
      PROFILE_TYPE.kafkaAzureAD,
    ],
  },
  packetCaptures: {
    path: '/investigation/packet-captures',
    profileTypes: [PROFILE_TYPE.zeek, PROFILE_TYPE.zeekV2],
  },
  [PAGES.baseline]: {
    path: '/reporting/baseline',
    profileTypes: ZEEK_SENSOR_PROFILE_TYPES,
  },
  [PAGES.executiveSummary]: {
    path: '/reporting/executive-summary',
    profileTypes: [
      ...ZEEK_SENSOR_PROFILE_TYPES,
      ...ACTIVITYLOG_PROFILE_TYPES,
      ...FLOWLOGS_PROFILE_TYPES,
      // identity profile types don't really belong here but they are
      // an exception to the current system because we need them to be
      // selectable in the sensor selector but don't want them to enable
      // the menu item if a user has only IDM sensors
      ...IDENTITY_PROFILE_TYPES,
      PROFILE_TYPE.awsApiGatewayLogs,
    ],
  },
};

export const EVENTS_ENABLED_PROFILE_TYPES = [
  ...ZEEK_SENSOR_PROFILE_TYPES,
  ...ACTIVITYLOG_PROFILE_TYPES,
  ...AZURE_AD_PROFILE_TYPES,
  ...CROWDSTRIKE_PROFILE_TYPES,
];

export const PATH_PROFILE_TYPES = Object.values(SCENE).reduce(
  (acc, scene) => ({
    ...acc,
    [scene.path]: scene.profileTypes,
  }),
  {} as Record<string, ValueOf<typeof PROFILE_TYPE>[]>,
);

export const SEARCH_TYPE_LINKS = {
  O_EVENTS: SCENE.detections.path,
  O_ALERTS: SCENE.detections.path,
  O_INDICATORS: SCENE.indicators.path,
  O_SENSOR: SCENE.investigator.path,
  O_NOTIFICATIONS: '/notifications',
};

// scene to load on / if defaultView is set on API
export const DEFAULT_VIEW_MAP = {
  home: SCENE.alerts.path,
  detections:
    import.meta.env.VITE_DEPLOYMENT_TYPE !== 'enterprise'
      ? SCENE.detections.path
      : SCENE.detections.path +
        `?deviationScore=10&groupby=alertTypeName&q=Last%202%20hours&score=1&status=open&status=under_review&view=ds10`,
  executive_summary: SCENE['executive-summary'].path,
};
