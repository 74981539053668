import { PAGES } from 'consts/pages';
import { NONDIRECTIONAL_INDICATORS, AGG_USER_ACTIVITY, AGG_ENTITY_ACTIVITY } from './logtype';
import { CLOUDTRAIL_PROFILE_TYPES, PROFILE_TYPE, ZEEK_SENSOR_PROFILE_TYPES } from './sensors';

const PERMANENT_COLUMNS = {
  default: ['@/ts'],
  [PAGES.events]: ['@/openEventRecord', '@/ts'],
  [PAGES.indicators]: ['@/openRecord', '@/ts'],
  [PAGES.investigator]: ['@/openRecord', '@/ts'],
  [PAGES.cloudapi]: ['@/openRecord', '@/ts'],
  [PAGES.identityManagement]: ['@/openRecord', '@/ts'],
};

const CUSTOM_FIELDS = {
  default: ['sensorName / tenantName'],
  // exceptions
  [PAGES.indicators]: ['sensorName / tenantName', 'value / key'],
};

const DEFAULT_COLUMNS = {
  default: ['sensorName / tenantName', 'log', 'data'],
  // exceptions
  // page
  [PAGES.events]: [
    'impact_score',
    'sensorName / tenantName',
    'count',
    'impact',
    'killchain',
    'affectedHosts',
    'status',
  ],
  [PAGES.indicators]: [
    'severity',
    'deviation_score',
    'sensorName / tenantName',
    'log',
    'value / key',
    'killchain',
    'geo',
    'entity',
  ],
  [PAGES.cloudapi]: ['severity', 'deviation_score', 'sensorName / tenantName', 'log', 'value / key'],
  [PAGES.identityManagement]: ['severity', 'deviation_score', 'sensorName / tenantName', 'log', 'value / key'],
  // log type
  ...[...NONDIRECTIONAL_INDICATORS, ...AGG_USER_ACTIVITY, ...AGG_ENTITY_ACTIVITY].reduce(
    (xs, log) => ({
      ...xs,
      [log]: ['log', 'data'],
    }),
    {},
  ),
};

const getFields = (page, log, config) => {
  return log ? config[log] || config.default : config[page] || config.default;
};

export const getPermanentColumns = ({ page, log }) => {
  return getFields(page, log, PERMANENT_COLUMNS);
};

export const getCustomFields = ({ page, log }) => {
  return getFields(page, log, CUSTOM_FIELDS);
};

export const getDefaultColumns = ({ page, log }) => {
  return getFields(page, log, DEFAULT_COLUMNS);
};

export const PRESET_OPTIONS = {
  [PAGES.events]: [
    {
      label: 'Custom',
      value: 'custom',
      defaultWidgets: [
        { type: 'radarchart', dataKey: 'killchain' },
        { type: 'activeshapepiechart', dataKey: 'deviation_score' },
        { type: 'simpletable', dataKey: 'affectedHosts' },
      ],
    },
  ],
  [PAGES.indicators]: [
    {
      label: 'Custom',
      value: 'custom',
      defaultWidgets: [
        { type: 'simpletable', dataKey: 'log' },
        { type: 'simpletable', dataKey: 'killchain' },
        { type: 'activeshapepiechart', dataKey: 'geo' },
        { type: 'simpletable', dataKey: 'entity' },
      ],
    },
  ],
  [PAGES.investigator]: [
    {
      label: 'Zeek',
      value: 'zeek',
      profileTypes: ZEEK_SENSOR_PROFILE_TYPES,
      defaultWidgets: [
        { type: 'simpletable', dataKey: 'log' },
        { type: 'simpletable', dataKey: 'id_orig_h' },
        { type: 'simpletable', dataKey: 'id_resp_h' },
        { type: 'simpletable', dataKey: 'id_orig_p' },
        { type: 'simpletable', dataKey: 'id_resp_p' },
        { type: 'simpletable', dataKey: 'mm_proto' },
        { type: 'simpletable', dataKey: 'id_src_geo' },
        { type: 'simpletable', dataKey: 'id_dest_geo' },
        { type: 'simpletable', dataKey: 'geo.country_name' },
        { type: 'activeshapepiechart', dataKey: 'deviation_score' },
        { type: 'simpletable', dataKey: 'orig_bytes' },
        { type: 'simpletable', dataKey: 'resp_bytes' },
      ],
    },
    {
      label: 'Cloudtrail',
      value: 'cloudtrail',
      profileTypes: CLOUDTRAIL_PROFILE_TYPES,
      defaultWidgets: [
        { type: 'simpletable', dataKey: 'cloudtrail.eventName' },
        { type: 'simpletable', dataKey: 'cloudtrail.eventCategory' },
        { type: 'simpletable', dataKey: 'cloudtrail.eventSource' },
        { type: 'simpletable', dataKey: 'cloudtrail.userIdentity.type' },
        { type: 'simpletable', dataKey: 'cloudtrail.userIdentity.principalId' },
        { type: 'simpletable', dataKey: 'cloudtrail.userIdentity.arn' },
        { type: 'simpletable', dataKey: 'cloudtrail.userIdentity.accountId' },
        { type: 'simpletable', dataKey: 'cloudtrail.awsRegion' },
        { type: 'simpletable', dataKey: 'cloudtrail.errorCode' },
      ],
    },
    {
      label: 'Okta',
      value: 'okta',
      profileTypes: [PROFILE_TYPE.oktaLogs],
      defaultWidgets: [
        { type: 'simpletable', dataKey: 'log' },
        { type: 'simpletable', dataKey: 'okta_logs.actor.alternateId' },
        { type: 'simpletable', dataKey: 'okta_logs.actor.displayName' },
        { type: 'simpletable', dataKey: 'okta_logs.actor.type' },
        { type: 'simpletable', dataKey: 'okta_logs.target.alternateId' },
        { type: 'simpletable', dataKey: 'okta_logs.target.displayName' },
        { type: 'simpletable', dataKey: 'okta_logs.target.type' },
        { type: 'simpletable', dataKey: 'okta_logs.eventType' },
        { type: 'simpletable', dataKey: 'okta_logs.displayMessage' },
        { type: 'simpletable', dataKey: 'okta_logs.outcome.result' },
        { type: 'simpletable', dataKey: 'okta_logs.outcome.reason' },
        { type: 'simpletable', dataKey: 'okta_logs.client.geographicalContext.country' },
        { type: 'simpletable', dataKey: 'okta_logs.client.geographicalContext.state' },
        { type: 'simpletable', dataKey: 'okta_logs.client.geographicalContext.city' },
      ],
    },
    {
      label: 'AWS Gateway',
      value: 'awsGateway',
      profileTypes: [PROFILE_TYPE.awsApiGatewayLogs],
      defaultWidgets: [
        { type: 'simpletable', dataKey: 'gateway.endpointExchangeASV' },
        { type: 'simpletable', dataKey: 'gateway.clientExchangeASV' },
        { type: 'simpletable', dataKey: 'gateway.ipAddressSourceAsvName' },
        { type: 'simpletable', dataKey: 'gateway.deviceURI' },
        { type: 'simpletable', dataKey: 'gateway.deviceResponseBodySize' },
        { type: 'simpletable', dataKey: 'gateway.deviceHTTPMETHOD' },
        { type: 'simpletable', dataKey: 'gateway.ipAddressSource' },
        { type: 'simpletable', dataKey: 'gateway.ipAddressSourceOwnerContact' },
        { type: 'simpletable', dataKey: 'gateway.ipAddressSourceTechnologyDivision' },
        { type: 'simpletable', dataKey: 'gateway.ipAddressDestination' },
        { type: 'simpletable', dataKey: 'gateway.ipAddressDestinationOwnerContact' },
        { type: 'simpletable', dataKey: 'gateway.ipAddressDestinationTechnologyDivision' },
        { type: 'simpletable', dataKey: 'gateway.deviceUser' },
        { type: 'simpletable', dataKey: 'gateway.clientAppName' },
        { type: 'simpletable', dataKey: 'gateway.ipAddressSourceBusinessApplicationName' },
      ],
    },
    {
      label: 'Microsoft Graph API Log',
      value: 'msGraphApiLog',
      profileTypes: [PROFILE_TYPE.msGraphLogs],
      defaultWidgets: [
        { type: 'simpletable', dataKey: 'ms_graph.resourceDisplayName' },
        { type: 'simpletable', dataKey: 'ms_graph.userDisplayName' },
        { type: 'simpletable', dataKey: 'ms_graph.userPrincipalName' },
        { type: 'simpletable', dataKey: 'ms_graph.appDisplayName' },
        { type: 'simpletable', dataKey: 'ms_graph.category' },
        { type: 'simpletable', dataKey: 'ms_graph.activityDisplayName' },
        { type: 'simpletable', dataKey: 'ms_graph.location.state' },
        { type: 'simpletable', dataKey: 'ms_graph.location.countryOrRegion' },
        { type: 'simpletable', dataKey: 'ms_graph.ipAddress' },
        { type: 'simpletable', dataKey: 'route' },
      ],
    },
    {
      label: 'Microsoft Active Directory',
      value: 'kafkaAzureAD',
      profileTypes: [PROFILE_TYPE.kafkaAzureAD],
      defaultWidgets: [
        { type: 'simpletable', dataKey: 'ms_ad.host.name' },
        { type: 'simpletable', dataKey: 'ms_ad.winlog.event_data.TargetUserName' },
        { type: 'simpletable', dataKey: 'ms_ad.winlog.event_data.IpAddress' },
        { type: 'simpletable', dataKey: 'ms_ad.event.action' },
        { type: 'simpletable', dataKey: 'ms_ad.event.outcome' },
        { type: 'simpletable', dataKey: 'ms_ad.event.provider' },
        { type: 'simpletable', dataKey: 'ms_ad.winlog.channel' },
        { type: 'simpletable', dataKey: 'ms_ad.winlog.event_id' },
        { type: 'simpletable', dataKey: 'ms_ad.winlog.opcode' },
        { type: 'simpletable', dataKey: 'ms_ad.winlog.keywords' },
        { type: 'simpletable', dataKey: 'ms_ad.winlog.event_data.param2' },
        { type: 'simpletable', dataKey: 'ms_ad.winlog.event_data.TaskName' },
      ],
    },
    {
      label: 'CrowdStrike',
      value: 'crowdstrike',
      profileTypes: [PROFILE_TYPE.crowdstrikeApi],
      defaultWidgets: [
        { type: 'simpletable', dataKey: 'ComputerName' },
        { type: 'simpletable', dataKey: 'UserName' },
        { type: 'simpletable', dataKey: 'id_orig_h' },
        { type: 'simpletable', dataKey: 'OperationName' },
        { type: 'simpletable', dataKey: 'DetectName' },
        { type: 'simpletable', dataKey: 'DetectDescription' },
        { type: 'simpletable', dataKey: 'Severity' },
        { type: 'simpletable', dataKey: 'SeverityName' },
      ],
    },
    {
      label: 'Custom',
      value: 'custom',
      defaultWidgets: [{ type: 'simpletable', dataKey: 'log' }],
    },
  ],
};
