import { useInterval } from 'utils/hooks';

import useSearchAlternated from './useSearchAlternated';

const useAiPrediction = () => {
  const search = useSearchAlternated({
    searchType: 'O_INDICATORS',
    pqlQuery: 'last 10 minutes log = "risk_prediction" deviation_score > 7',
    pqlAlternation: '| countby key, sensorId',
    context: [],
    skipRegistry: true,
    registryGroupKey: 'risk_prediction',
  });

  useInterval(
    () => {
      search.refetch();
    },
    1000 * 60 * 3,
  );

  return search.data;
};

export default useAiPrediction;
