import type { ValueOf } from 'types/helpers';

export const PROFILE_TYPE = {
  flowlogs: 'aws_vpc', // zeek-like flowlogs
  cloudtrail: 'aws_cloudtrail',
  zeek: 'sensor',
  zeekV2: 'zeek_v2',
  oldZeek: 'zeek', // should be deprecated
  // new set of cloud types
  awsCloudtrail: 'aws_cloudtrail_v2',
  awsFlowlogs: 'aws_flowlogs_v2',
  azureActivitylog: 'azure_activitylog_v2',
  azureFlowlogs: 'azure_flowlogs_v2',
  googleActivitylog: 'google_activitylog_v2',
  googleFlowlogs: 'google_flowlogs_v2',
  oktaLogs: 'okta_logs',
  msGraphLogs: 'ms_graph_logs_v2',
  kafkaAzureAD: 'kafka_azure_ad',
  crowdstrikeV2: 'crowdstrike_v2',
  crowdstrikeApi: 'crowdstrike_api',
  awsApiGatewayLogs: 'aws_api_gateway_logs',
  kafkaConnector: 'kafka_connector',
} as const;

export const PROFILE_TYPE_LABEL = {
  [PROFILE_TYPE.oktaLogs]: 'Okta Identity Management',
  [PROFILE_TYPE.msGraphLogs]: 'Microsoft Graph API Log',
  [PROFILE_TYPE.kafkaAzureAD]: 'Azure AD over Kafka',
  [PROFILE_TYPE.crowdstrikeV2]: 'Crowdstrike FDR',
  [PROFILE_TYPE.crowdstrikeApi]: 'Crowdstrike API',
  [PROFILE_TYPE.kafkaConnector]: 'Kafka Connector',
  [PROFILE_TYPE.awsCloudtrail]: 'Configuration | Select your MixMode platform deployment type',
} as const;

export const NON_SENSOR_PROFILE_TYPES = ['script', 'intel'];

// list without azure/google -
// used **ONLY** for filtered querying on ProfilesProvider/getSensorProfiles
// this allows us to prevent creation of new profiles for other profile types
export const ACTIVE_PROFILE_TYPE = {
  zeekV2: 'zeek_v2',
  oldZeek: 'zeek', // should be deprecated
  awsCloudtrail: 'aws_cloudtrail_v2',
  awsFlowlogs: 'aws_flowlogs_v2',
  azureActivitylog: 'azure_activitylog_v2',
  azureFlowlogs: 'azure_flowlogs_v2',
  oktaLogs: 'okta_logs',
  // crowdstrikeV2: 'crowdstrike_v2',
  crowdstrikeApi: 'crowdstrike_api',
  msGraphLogs: 'ms_graph_logs_v2',
  kafkaAzureAD: 'kafka_azure_ad',
  kafkaConnector: 'kafka_connector',
};

export const ZEEK_SENSOR_PROFILE_TYPES = [
  PROFILE_TYPE.zeek,
  PROFILE_TYPE.zeekV2,
  PROFILE_TYPE.oldZeek,
  PROFILE_TYPE.flowlogs,
  PROFILE_TYPE.kafkaConnector,
];

export const ACTIVITYLOG_PROFILE_TYPES = [
  PROFILE_TYPE.cloudtrail,
  PROFILE_TYPE.awsCloudtrail,
  PROFILE_TYPE.azureActivitylog,
  PROFILE_TYPE.googleActivitylog,
];

export const FLOWLOGS_PROFILE_TYPES = [
  PROFILE_TYPE.awsFlowlogs,
  PROFILE_TYPE.azureFlowlogs,
  PROFILE_TYPE.googleFlowlogs,
];

export const IDENTITY_PROFILE_TYPES = [PROFILE_TYPE.oktaLogs, PROFILE_TYPE.msGraphLogs];

export const CLOUDTRAIL_PROFILE_TYPES = [PROFILE_TYPE.cloudtrail, PROFILE_TYPE.awsCloudtrail];

export const CROWDSTRIKE_PROFILE_TYPES = [PROFILE_TYPE.crowdstrikeV2, PROFILE_TYPE.crowdstrikeApi];

export const APIGW_PROFILE_TYPES = [PROFILE_TYPE.awsApiGatewayLogs];

export const AZURE_AD_PROFILE_TYPES = [PROFILE_TYPE.msGraphLogs, PROFILE_TYPE.kafkaAzureAD];

// doesnt include kafkaConnector as its part of zeek sensor profiles
export const KAFKA_PROFILE_TYPES = [PROFILE_TYPE.kafkaAzureAD];

// enables the sync button on sensor config page
export const SYNC_ENABLED_PROFILE_TYPES = [
  ...ZEEK_SENSOR_PROFILE_TYPES,
  PROFILE_TYPE.awsFlowlogs,
  PROFILE_TYPE.awsCloudtrail,
  PROFILE_TYPE.azureActivitylog,
  PROFILE_TYPE.azureFlowlogs,
  PROFILE_TYPE.oktaLogs,
  PROFILE_TYPE.awsApiGatewayLogs,
  ...AZURE_AD_PROFILE_TYPES,
  PROFILE_TYPE.crowdstrikeApi,
  PROFILE_TYPE.crowdstrikeV2,
  PROFILE_TYPE.kafkaConnector,
];

// for validating SMF profiles
// validationType sent to API equals profileType unless specifically overridden here
export const SMF_VALIDATION_TYPE = (profileType: ValueOf<typeof PROFILE_TYPE> | 'bro' | 'script') => {
  switch (profileType) {
    case 'bro':
    case 'zeek':
    case 'zeek_v2':
    case 'sensor':
    case 'script':
      return 'bro';
    default:
      return profileType;
  }
};

// usage page
export const CLOUD_SENSOR_PROFILE_DATA = {
  flowlogs: {
    name: 'Cloud Flow Log Monitoring',
    color: '#00bbb7',
  },
  cloudapi: {
    name: 'Cloud API Monitoring',
    color: '#de7cdd',
  },
  apigw: {
    name: 'Cloud API Gateway Monitoring',
    color: '#f0b856',
  },
  idm: {
    name: 'Identity Management',
    color: '#0088DD',
  },
  crowdstrike: {
    name: 'Endpoint Log',
    color: '#FF6F61',
  },
  kafka: {
    name: 'Kafka Ingest',
    color: '#00bbb7',
  },
};
